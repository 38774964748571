export function isGreekNumber(value) {
  return value && /^(\+30|0030)?69\d{8}$/.test(value.replace(/[\s\-().]/g, ''));
}

export function isCardNumber(value) {
  const parsePattern = new RegExp(process.env.cardPattern);
  return value && parsePattern.test(value.replace(/[^0-9]/g, ''));
}

export default { isGreekNumber, isCardNumber };

import Head from 'next/head';

export default function CustomHead({ children, title }) {
  return (
    <Head>
      <title>
        {title} | {process.env.metaBrandTitle}
      </title>
      {children}
    </Head>
  );
}

import axios from 'axios';
import get from 'lodash/get';

import { updateUser as cpUpdateUser } from '../../utils/contactPigeon';

const baseURL = `${process.env.NEXT_PUBLIC_WAVE_HOSTNAME}/auth`;
const AUTH = axios.create({
  baseURL,
  headers: {
    appId: process.env.appId,
  },
});

export async function loginSendOTP(number, type) {
  try {
    const response = await AUTH.get(`/sms/confirmation/${type}/${number}`);
    return response.data;
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @returns User object or undefined
 */
export async function loginVerifyOTP(body) {
  try {
    const response = await AUTH.post('/sms/login', body);

    const user = get(response, 'data.payload.users');
    if (!user) {
      console.error(response);
      throw Error('No user found in response.');
    }

    cpUpdateUser(user.email);
    return user;
  } catch (error) {
    return error?.response?.data;
  }
}

export default { loginSendOTP, loginVerifyOTP };

// import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import get from 'lodash/get';
import Router from 'next/router';
import { useEffect, useState } from 'react';
import { IoMdCheckmarkCircleOutline } from 'react-icons/io';
import { RiErrorWarningLine } from 'react-icons/ri';
import OtpInput from 'react-otp-input';
import Cookies from 'universal-cookie';

import authAPI from '../../api/wave/auth';
import { ORDER } from '../../api/wave/orders';
import userAPI from '../../api/wave/users';
import { AuthModal, AuthModalView, AuthVerificationToken } from '../../store/authModalShow';
import GuestUserStore from '../../store/guestUser';
import Loader from '../../store/loader';
import User from '../../store/user';
import verification from '../../utils/verifications';
import { PrimaryButton } from '../Buttons/Buttons';

import styles from './OTPView.module.scss';

export default function OTPView({ method, userId, successCallback = () => {}, children }) {
  const [otpInputsValues, setOtpInputsValues] = useState({ otp: '' });
  const [errorMsg, setErrorMsg] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [resendOtpErrorMessage, setResendOtpErrorMessage] = useState('');
  const [resendOtpSuccessMessage, setResendOtpSuccessMessage] = useState('');
  const [user, setUser] = useState({});

  const cookies = new Cookies();

  function onChangeOTP(otp) {
    setErrorMsg('');
    setOtpInputsValues({ otp });
  }

  function createTokenCookie(authToken) {
    const cookieExpirationDate = dayjs().add(15, 'day').toDate();
    cookies.set('token', `${authToken}`, {
      expires: cookieExpirationDate,
      path: '/',
    });
  }

  function verifyOTPWrapper(callback) {
    // setIsLoginCallBeingMade(true);
    const data = {
      code: otpInputsValues.otp,
      verificationToken: AuthVerificationToken.getValue() || userId,
    };
    Loader.addEvent('VERIFY_OTP');
    callback(data)
      .then(response => {
        if (response?.error) {
          setErrorMsg(response?.error?.userMessage);
          // setIsLoginCallBeingMade(false);
          return;
        }
        createTokenCookie(response.token);
        User.update(response);
        ORDER.defaults.headers.authorization = `Bearer ${response.token}`;
        AuthModal.update(false);
        AuthModalView.update('login');
        // setIsLoginCallBeingMade(false);
        AuthVerificationToken.update('');
        if (method === 'login') {
          Router.push('/', '/');
        } else {
          successCallback();
        }
      })
      .catch(error => {
        console.error(JSON.stringify(error, null, 2));
        setErrorMsg(error.error.userMessage);
      })
      .finally(() => {
        GuestUserStore.setInitUser();
        Loader.removeEvent('VERIFY_OTP');
      });
  }

  function requestOTP() {
    setErrorMsg('');
    const _id = userId || AuthVerificationToken.getValue() || mobileNumber;
    const numberIdNoSpace = _id.replace(/\s/g, '');

    Loader.addEvent('RESEND_TOP');
    const verificationMethod = verification.isGreekNumber(numberIdNoSpace) ? 'mobile' : 'card';
    authAPI
      .loginSendOTP(numberIdNoSpace, verificationMethod)
      .then(response => {
        setMobileNumber(response.payload.verification.verificationToken);
        setResendOtpErrorMessage('');
        setResendOtpSuccessMessage('Ο κωδικός έχει αποσταλεί ξανά.');
        setTimeout(() => {
          setResendOtpSuccessMessage('');
        }, 3000);
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.userMessage', 'Δεν ήταν δυνατό να αποσταλεί ο κωδικός.');
        setResendOtpSuccessMessage('');
        setResendOtpErrorMessage({ errorMessage });
      })
      .finally(() => {
        Loader.removeEvent('RESEND_TOP');
      });
  }

  function onSubmitOTP(e) {
    e.preventDefault();
    if (method === 'login' || method === 'login-digital') {
      verifyOTPWrapper(authAPI.loginVerifyOTP);
    } else {
      verifyOTPWrapper(userAPI.verifyCode);
    }
  }

  useEffect(() => {
    const _user = User.getValue();
    setUser(_user);
  }, []);

  return (
    <section className={styles.container}>
      {children}
      <div className={styles.imageContainer}>
        <img
          className={styles.img}
          width="200"
          height="200"
          src={`/assets/${process.env.appName}/${process.env.appName}-login.png`}
          alt="grocery app"
        />
      </div>
      <p className={styles.p}>
        Σου έχουμε στείλει έναν κωδικό με SMS στο {user?.maskedMobile}, παρακαλούμε συμπλήρωσε τον εδώ.
      </p>
      <form noValidate className={styles.form} onSubmit={onSubmitOTP}>
        <div className={styles.otpContainer}>
          <OtpInput
            isInputNum
            inputStyle={styles.otpInput}
            hasErrored={Boolean(errorMsg)}
            errorStyle={styles.otpError}
            data-cy="otp"
            value={otpInputsValues.otp}
            onChange={onChangeOTP}
            numInputs={process.env.otpLength}
            shouldAutoFocus
            separator={<span className={styles.separator}> </span>}
          />
        </div>
        {errorMsg && <p className={styles.errorMsg}>{errorMsg}</p>}
        <div className={styles.primaryButtonContainer}>
          <PrimaryButton type="submit" data-cy="log-in" style={{ width: '100%' }}>
            Είσοδος
          </PrimaryButton>
        </div>
      </form>
      <div className={styles.flexCenter}>
        <p className={styles.footerText}>Δεν σας ήρθε ο κωδικός; </p>
        <button type="button" className={styles.footerButton} onClick={requestOTP}>
          Αποστολή ξανά
        </button>
      </div>
      {resendOtpErrorMessage && (
        <p className={styles.resendOtpErrorMessage}>
          <RiErrorWarningLine className={styles.resendIcon} /> {resendOtpErrorMessage}
        </p>
      )}
      {resendOtpSuccessMessage && (
        <p className={styles.resendOtpSuccessMessage}>
          <IoMdCheckmarkCircleOutline className={styles.resendIcon} />
          {resendOtpSuccessMessage}
        </p>
      )}
    </section>
  );
}
